<template>
  <div class="container-main">
    <template v-if="type_active === 'login'">
      <span class="title"> {{ $t('ct-sign-in') }} </span>
      <login @change="changeType"></login>
      <button class="btn btn-secondary text-center" @click.prevent="changeType('register')"
              v-if="!config.hide_register_btn">{{ $t('ct-create-account') }}
      </button>
      <a href="#" class="text-center d-block mt-2 helper-forgot-password"
         @click.prevent="changeType('remember')">{{ $t('ct-forgot-password') }}</a>
    </template>

    <template v-if="type_active === 'register'">
      <span class="title"> {{ $t('ct-register') }} </span>
      <register :areas_register="areas_register" @change="changeType"></register>
      <a href="#" class="text-center d-block mt-2 helper-forgot-password"
         @click.prevent="changeType('login')">{{ $t('ct-sign-in-account') }}</a>
    </template>

    <template v-if="type_active === 'login-ba'">
      <span class="title">{{ $t('ct-access-ba-credentials') }}</span>
      <login-builderall :areas_register="areas_register" @change="changeType"></login-builderall>
      <a href="#" class="helper-forgot-password text-center d-block mt-2"
         v-if="auth_types.includes('register') || auth_types.includes('both')"
         @click.prevent="changeType('login')">{{ $t('ct-sign-in-account') }}</a>
    </template>

    <template v-if="type_active === 'remember'">
      <span class="title">{{ $t('ct-forgot-password') }}</span>
      <remember-password @change="changeType"></remember-password>

      <div class="mx-5 mt-2 mb-2 d-flex">
        <div class="text-center flex-grow-1">
          <template v-if="!config.hide_register_btn">
            <a href="#" class="helper-forgot-password"
               @click.prevent="changeType('register')">{{ $t('ct-register') }}</a>
            <span class="mx-1 helper-forgot-password">|</span>
          </template>
          <a href="#" class="helper-forgot-password" @click.prevent="changeType('login')">{{ $t('ct-sign-in') }}</a>
        </div>
      </div>
    </template>

    <template v-if="type_active === 'login-token'">
      <span class="title">{{ $t('ct-access-with-token') }}</span>
      <login-token @change="changeType"></login-token>
    </template>

    <template v-if="type_active === 'not-have-permission'">
      <not-have-permission></not-have-permission>
    </template>
  </div>
</template>

<script>

    import {mixinRestrictAreaConfig, mixinRestrictAreaForm} from './../../../../../js/mixins/restrictAreaForm';

    export default {
        name: "InBlockPopUp",
        mixins: [mixinRestrictAreaForm, mixinRestrictAreaConfig],
    }
</script>
